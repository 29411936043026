<template>
  <div class="w1200">
    <div v-html="content" class="zhiyelinian"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      content:''
    }
  },
  mounted() {
    this.getZhiyelinian()
  },
  methods:{
   getZhiyelinian() {
      this.$api.getZhiyelinian().then(res=>{
        this.content = res.data.contents
      })
    }
  }
}
</script>
<style>
.zhiyelinian{
  overflow: hidden;
}
.zhiyelinian img{
  max-width: 100%;
}
</style>